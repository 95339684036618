<template>
  <nav class="main-navigation"  @mouseleave="menuIsOpen = false">
    <div>
      <div class="container">
        <ul>
          <li v-for="(item, index) in mainNavItems" :key="item.url" class="nav-main-1st" :class="item.children ? 'dropdown' : ''" @mouseover="openMenu(index)" :ref="'tab-' + index">
            <SpaLink v-if="!item.url.includes('https')" :href="item.url" v-html="item.label"></SpaLink>
            <a v-else :href="item.url" v-html="item.label"></a>
          </li>
        </ul>
        <span class="subnav-arrow" :style="{ left: arrowPositionLeft + 'px', display: menuIsOpen ? 'inline' : 'none' }"/>
      </div>
      <SubNavigation :class="menuIsOpen ? 'open' : ''" :data="mainNavItems[activeMenuItem]"/>
    </div>
  </nav>
</template>

<script>
  import SpaLink from "./SpaLink";
  import SubNavigation from "./SubNavigation";
  import mainNavItems from "../data/header-items.main.json";

  export default {
    name: "MainNavigation",
    components: { SpaLink, SubNavigation },
    methods: {
      openMenu(index) {
        this.menuIsOpen = true;
        this.activeMenuItem = index
        const left = this.$refs['tab-'+index].offsetLeft;
        const width = this.$refs['tab-'+index].offsetWidth;
        this.arrowPositionLeft = left + 0.5 * width - 13;
      }
    },
    data() {
      return {
        menuIsOpen: false,
        activeMenuItem: 0,
        arrowPositionLeft: 0,
        mainNavItems
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../assets/variables.scss';

  .container {
    padding: 0 1.25rem;
    position: relative;

    @media (min-width: $break-xl) {
      width: 75rem;
      margin: 0 auto;
    }
  }

  .subnav-arrow {
    position: absolute;
    top: 42px;
    transition: left 1s;
    width: 1.25rem;
    height: 1.25rem;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    background: $color-primary;
    transform: rotate(45deg);
    z-index: 3000;
  }


  .main-navigation {
    li.nav-main-2nd > a {
      font-weight: bold;
      display: inline-block;
    }

    ul {
      display: flex;
      justify-content: space-between;
      gap: 2.4375rem;

      li.dropdown {
        position: relative;
      }

      li {
        display: inline-block;
        padding: .8125rem 0 1.5625rem;
        font-size: .75rem;
        white-space: nowrap;

        a {
          text-transform: uppercase;
          text-decoration: none;
          letter-spacing: .0275rem;
          color: $color-accent-darkest;
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }

    @media (min-width: $break-lg) {
      ul > li {
        font-size: .875rem;
      }

      .subnav-arrow {
        top: 46px;
      }
    }
    @media (min-width: $break-xl) {
      ul > li {
        font-size: 1rem
      }
      .subnav-arrow {
        top: 48px;
      }
    }

  }

</style>