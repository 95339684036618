<template>
  <a :class="linkClass" :href="href" @click.prevent="navigate">
    <slot></slot>
  </a>
</template>

<script>
  export default {
    name: "SpaLink",
    props: ["linkClass", "href"],
    methods: {
      navigate() {
        history.pushState(null, null, this.href);
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../assets/variables.scss';

  a {
    font: inherit;
    text-decoration: none;
    color: inherit
  }

</style>