<template>
  <div :class="isOpen ? `open secondary-level ${type}` : `secondary-level ${type}`">
    <ul v-if="data">
      <li class="back" @click="$emit('update:isOpen', false)"><i class="icon-icon-left"/>Zurück</li>
      <li class="title">
        <SpaLink v-if="data && !data.url.includes('https')" @click="handleToggle" :href="data.url">{{ data.label }}</SpaLink>
        <a v-else-if="data" @click="handleToggle" :href="data.url">{{ data.label }}</a>
      </li>
      <li v-for="(child, index) in data.children" :key="index">
        <SpaLink v-if="child && !child.url.includes('https')" @click="handleToggle" :href="child.url" v-html="child.label"></SpaLink>
        <a v-else-if="child" @click="handleToggle" :href="child.url" v-html="child.label"></a>
      </li>
    </ul>
  </div>
</template>

<script>
  import SpaLink from './SpaLink.vue';

  export default {
    name: "SecondaryLevel",
    components: { SpaLink },
    props: ["data", "isOpen", "type"],
    emits: ['update:isOpen']
  }
</script>

<style lang="scss" scoped>
  @import '../assets/variables.scss';

  .secondary-level {
    position: absolute;
    top: 0;
    background: $color-primary;
    width: 100%;
    min-height: 100%;
    z-index: 2000;
    right: -100%;
    opacity: 0;
    transition: all .3s ease-in;
    padding: 0;

    &.meta {
      background: white;
      ul {
        padding: 0;
        li, a {
          color: $color-grey-light;
        }
      }
    }

    &.open {
      right: 0;
      transition: all .3s ease-in;
      opacity: 1;
    }

    ul {
      text-align: center;
      list-style: none;
      padding: 2.1875rem 0;

      li {
        line-height: 2.5;
      }

      .back {
        text-transform: uppercase;
        font-size: .75rem;
        i {
          font-weight: 700;
          margin-right: 1em;
          font-size: .625rem;
          line-height: 2.4375rem;
          padding-bottom: 2px;
        }
      }

      .title {
        text-transform: uppercase;
        font-weight: 500;
      }

      a {
        text-decoration: none;
        color: $color-accent-dark;
      }
    }
  }
</style>