<template>
  <div class='search-bar'>
    <form @submit='submit'>
      <div class='bar'> 
        <input autofocus ref='search' autocomplete='off' placeholder='Wonach suchst du?' v-model='searchInput'>
        <a class='button-submit' :href='shopRef'>
          <i class="icon-search"></i>
        </a>
      </div>
    </form>
  </div>
</template>

<script>
  export default {
    name: 'SearchBarShop',
    data() {
      return {
        searchInput: '',
      }
    },
    methods: {
      submit(e) {
        console.log('submit', e, this.searchInput);
        e.preventDefault();
        if (this.searchInput) {
          document.location=this.shopRef;
        }
        return false;
      }
    },
    computed: {
      shopRef() {
        return `/shop/search?sSearch=${this.searchInput}`;
      }
    }
  }
</script>

<style lang='scss' scoped>
  @import '../assets/variables';

  .search-bar {
    width: 100%;

    form {
      .bar {
        display: flex;

        input {
          height: 2.5rem;
          font-size: 1rem;
          padding: 0 2.0625rem 0 .6875rem;
          line-height: 1.375rem;
          letter-spacing: .0625rem;
          border: 0;
          border-radius: 0;
          width: 80%;

          &:focus {
            outline: 0;
          }
        }

        input::placeholder {
          color: $color-grey-light;
          font-size: .875rem;
        }

        .button-submit {
          display: inline-block;
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20%;
          background-color: $color-secondary;
          text-align: center;
          letter-spacing: 1px;
          color: white;
          font-size: 1rem;
          padding: .625rem;

          &:hover {
            box-shadow: 6px 6px 11px rgba(0,0,0,.2);
          }
        }
      }
    }


    @media (min-width: $break-md) {
      form {
        .bar {
          input {
            height: 2.75rem;
            padding: 0 2.0625rem;

            &::placeholder {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
</style>