<template>
	<div>
		<meta-navigation />
		<div class="container">
			<div class="center-bar">
				<a href="/" class="logo-container">
					<logo class="logo" />
				</a>
				<SearchBarShop />
				<user-management />
			</div>
		</div>
		<main-navigation />
	</div>
</template>

<script>
import MetaNavigation from "@/components/MetaNavigation";
import Logo from "@/components/Logo";
import SearchBarShop from "@/components/SearchBarShop";
import UserManagement from "@/components/UserManagement";
import MainNavigation from "@/components/MainNavigation";

export default {
	name: "HeaderDesktop",
	components: { MainNavigation, UserManagement, SearchBarShop, Logo, MetaNavigation }
};
</script>

<style lang="scss" scoped>
@import '../assets/variables.scss';

.container {
	padding: 0 1.25rem;

	@media (min-width: $break-xl) {
		width: 75rem;
		margin: 0 auto;
	}

	.center-bar {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.logo-container {
			width: 25%;

			.logo {
				margin-left: -1.25rem;
			}
		}

		.search-bar {
			width: 50%;
		}

		.user-management {
			width: 25%;
		}
	}
}
</style>