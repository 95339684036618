<template>
  <div>
    <div class="placeholder"/>
    <div class="menu-container">
      <div class="container">
        <nav>
          <ul>
            <li><a href="/"><logo/></a></li>
            <li class="menu-item"><a @click="toggleDictSearch"><i class="icon-dictionary"></i></a></li>
            <li class="menu-item"><a @click="toggleShopSearch"><i class="icon-search"></i></a></li>
            <li class="menu-item"><UserManagement/></li>
            <li class="menu-item"><BurgerMenu v-model:isActive="burgerIsActive"/></li>
          </ul>
        </nav>
      </div>

      <div :class=" + dictSearchIsOpen || shopSearchIsOpen ? 'search-container open' : 'search-container'">
        <div class="container">
          <div v-if="dictSearchIsOpen || shopSearchIsOpen" class="search-content">
            <SearchBarDict v-if="dictSearchIsOpen"/>
            <SearchBarShop v-if="shopSearchIsOpen"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Logo from "@/components/Logo";
  import BurgerMenu from './BurgerMenu.vue';
  import UserManagement from './UserManagement.vue';
  import SearchBarDict from './SearchBarDict.vue';
  import SearchBarShop from './SearchBarShop.vue';
  
  export default {
    name: "HeaderMobile",
    components: { Logo, BurgerMenu, UserManagement, SearchBarDict, SearchBarShop },
    methods: {
      toggleDictSearch() {
        this.burgerIsActive = false;
        if (this.shopSearchIsOpen) {
          this.shopSearchIsOpen = false;
        }
        this.dictSearchIsOpen = !this.dictSearchIsOpen;
      },
      toggleShopSearch() {
        this.burgerIsActive = false;
        if (this.dictSearchIsOpen) {
          this.dictSearchIsOpen = false;
        }
        this.shopSearchIsOpen = !this.shopSearchIsOpen;
      }
    },
    data() {
      return {
        dictSearchIsOpen: false,
        shopSearchIsOpen: false,
        burgerIsActive: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../assets/variables.scss';

  .placeholder {
    height: 3.75rem;
    width: 100vw;
  }

  .container {
    padding: 0 1.25rem;
  }

  .menu-container {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    box-sizing: border-box;
    z-index: 5000;
    background: $color-primary;
    
    nav {
      ul {
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
          display: block;
          text-decoration: none;
        }

        .logo {
          margin-left: -1.125rem;
        }

        .menu-item {
          padding: 0.9375rem;

          .icon-dictionary {
            font-size: 1.375rem;
            line-height: .9375rem;
            color: $color-accent-darkest;
          }

          &:last-child {
            margin-right: -0.9375rem;
          }
        } 
      }
    }
  }

  .search-container {
    max-height: 0;
    visibility: hidden;
    background: $color-primary;
    transition: max-height .2s;
    transition-delay: .1s;

    .container {
      opacity: 0;
      transition: opacity .6s;
    }
          
    .search-content {
      border-top: 2px solid white;
      padding: 1.6875rem 0;
    }

    &.open {
      visibility: visible;
      max-height: 100vh;
      box-shadow: 0 5px 11px -4px rgb(0 0 0 / 15%);
      transition: max-height .2s;

      .container {
        opacity: 1;
        transition: opacity .6s;
        transition-delay: .1s;
      }

    }
  }
</style>