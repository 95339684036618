<template>
  <nav class="meta-nav">
    <div class="container">
      <ul>
        <li v-for="item in metaNavItems" :key="item.url" :class="item.children ? 'dropdown' : ''">
          <SpaLink v-if="!item.url.includes('https')" :href="item.url" v-html="item.label"></SpaLink>
          <a v-else :href="item.url" v-html="item.label"></a>
          <ul v-if="item.children">
            <li v-for="child in item.children" :key="child.url">
              <SpaLink v-if="!child.url.includes('https')" :href="child.url" v-html="child.label"></SpaLink>
              <a v-else :href="child.url" v-html="child.label"></a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
  import SpaLink from "./SpaLink";
  import metaNavItems from "../data/header-items.top.json";

  export default {
    name: "MetaNavigation",
    components: { SpaLink },
    data() {
      return {
        metaNavItems
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../assets/variables";

  .container {
    padding: 0 1.25rem;

    @media (min-width: $break-xl) {
      width: 75rem;
      margin: 0 auto;
    }
  }

  .meta-nav {
    background-color: white;

    ul {
      display: flex;
      justify-content: flex-end;
      margin-right: -1.25rem;

      li.dropdown {
        position: relative;
        background-color: white;
      }

      li.dropdown:hover {
        box-shadow: 0 1px 11px 0 rgba(0, 0, 0, .15);

        ul {
          left: 0;
          position: absolute;
          display: block;
          z-index: 99;
          box-shadow: 0 4px 11px -3px rgba(0, 0, 0, .15);
          text-align: left;

          li {
            display: block;
            margin: 0;
            background-color: white;
          }
        }
      }

      li {
        display: inline-block;
        margin-left: .625rem;
        padding: 0 1.25rem;

        a {
          font-size: .8125rem;
          padding: .9375rem 0 .75rem;
          display: inline-block;
          line-height: 1;
          color: $color-grey-light;
          text-transform: uppercase;
          letter-spacing: .074em;
          white-space: nowrap;
          text-decoration: none;
        }

        a:hover {
          color: $color-accent-darkest;
        }

        ul {
          display: none;
        }
      }
    }
  }
</style>