<template>
	<div>
		<div class="user-management" @mouseenter="openAccountMenu" @mouseleave="closeAccountMenu">
			<span v-if="user">Mein Konto</span>
			<span v-else>Anmelden</span>
			<AccountMenu @close="closeAccountMenu"
						 :style="{display: accountMenuVisible ? 'block' : 'none'}" :direction="accountMenuAnimationDirection" />
		</div>
		<ShoppingCart class="shopping-cart" />
	</div>
</template>

<script>
import { clearAuth, requestAuth } from "@pons/auth";
//import SpaLink from './SpaLink.vue';
import ShoppingCart from "@/components/ShoppingCart";
import AccountMenu from "@/components/AccountMenu";

export default {
	name: "UserManagement",
	components: { ShoppingCart, AccountMenu },
	data() {
		return {
			user: null,
			accountMenuVisible: false,
			accountMenuAnimationDirection: "normal"
		};
	},
	methods: {

		openAccountMenu() {
			if (!this.accountMenuVisible) {
				this.accountMenuAnimationDirection = "normal";
				this.accountMenuVisible = true;
			}
		},

		closeAccountMenu() {
			if (this.accountMenuVisible) {
				this.accountMenuVisible = false;
				if (window.innerWidth < 768) {
					this.accountMenuAnimationDirection = "reverse";
					setImmediate(() => this.accountMenuVisible = true);
					setTimeout(() => this.accountMenuVisible = false, 195);
				}
			}
		},

		async enforceLogin() {
			this.user = await requestAuth({
				performAuthOnEmpty: true
			});
		},

		async logout() {
			await clearAuth();
		}
	},
	async created() {
		this.user = await requestAuth({ performAuthOnEmpty: false });
	}
};
</script>

<style lang="scss" scoped>
@import '../assets/variables';

div {
	display: flex;
	justify-content: flex-end;
	align-items: center;

	> div.user-management {
		display: inline-block;

		color: $color-accent-dark;
		text-decoration: none;

		@media (min-width: 1000px) {
			padding: 1rem 2rem;
		}

		@media (min-width: 768px) {
			position: relative;
		}

		@media (max-width: 999px) {
			padding: .3rem 4rem .3rem 1rem;
		}

		> span {
			font-weight: bold;
			font-size: 0.875rem;
			letter-spacing: .0625rem;
		}

		&:hover, &:focus {
			text-decoration: underline;
		}

		@media (min-width: $break-md) {
			a {
				display: inline;
			}
		}
	}

	div.shopping-cart {
		color: $color-accent-darkest;
		font-size: .875rem;
		letter-spacing: .0625rem;
		font-weight: 700;
	}

}

</style>
