<template>
	<div class="account-menu" :style="{animationDirection: direction}">
		<div class="close" @click="closeClicked">
			Menü schließen
			<img src="../assets/close.png" alt="close icon" height="17" width="10">
		</div>
		<h3>Kundenkonto</h3>
		<ul>
			<li v-if="!user" class="buttons">
				<div class="login">
					<a href="/shop/account#hide-registration">Anmelden</a>
				</div>
				<div class="register">
					<a href="/shop/account#show-registration">oder registrieren</a>
				</div>
			</li>
			<li class="overview" :class="user ? 'overview-active': ''">
				<a href="/shop/account" title="Übersicht"> Übersicht </a>
			</li>
			<li>
				<a href="/shop/account/profile" title="Mein Account">Mein Account</a>
			</li>
			<li>
				<a href="/shop/address/index/sidebar/" title="Rechnungs- und Lieferadressen">
					Rechnungs- und Lieferadressen
				</a>
			</li>
			<li>
				<a href="/shop/account/payment" title="Meine Zahlungsarten">Meine Zahlungsarten</a>
			</li>
			<li>
				<a href="/shop/account/orders" title="Meine Bestellungen">Meine Bestellungen</a>
			</li>
			<li>
				<a href="/account/license-manager" title="zu den Abonnements">Meine Abonnements</a>
			</li>
			<li v-if="user" class="logout">
				<a href="/shop/account/logout" title="Logout">
					<img width="40" height="41" src="../assets/logout.png" alt="Logout Symbol">
					Abmelden</a>
			</li>
		</ul>
	</div>
</template>

<script>
//import SpaLink from './SpaLink.vue';

import { requestAuth } from "@pons/auth";

export default {
	name: "AccountMenu",
	props: ["direction"],
	components: {},
	data() {
		return {
			user: null
		};
	},
	methods: {
		closeClicked() {
			this.$emit("close");
		}
	},
	async created() {
		this.user = await requestAuth({ performAuthOnEmpty: false });
	}
};
</script>

<style lang="scss" scoped>
@import '../assets/variables';

@media (min-width: 768px) {

	div.account-menu {

		&::after {
			transform: rotate(135deg);
			width: .5rem;
			height: .5rem;
			right: 1.125rem;
			content: '';
			display: block;
			position: absolute;
			background: #fff;
			border-color: #dadae5;
			border-width: 0 0 1px 1px;
			border-style: solid;
			top: -.25rem;
		}

		width: 16rem;
		margin-top: 0.25rem;
		z-index: 9999;
		position: absolute;
		right: 2.75rem;
		border: 1px solid #dadae5;

		background: #fff;

		> div {
			display: none;
		}

		h3 {
			font-family: "Gotham Narrow A", Helvetica, sans-serif;
			line-height: 1.625rem;
			font-size: 1rem;
			margin: 0 1.25rem;
			padding: 1.25rem 0 .625rem 0;
			text-decoration: none !important;
			border-bottom: 1px solid #dadae5;
		}

		ul {

			padding: .625rem 1.25rem;

			li {
				list-style: none;
				border: 0 none;
				padding: .1875rem 0;
				border-bottom: 1px solid #dadae5;
				line-height: 1.7rem;

				&.buttons {
					text-align: center;
					padding-bottom: .375rem;
					margin-bottom: .375rem;
					margin-top: .3125rem;

					div {
						border: none;

						a {
							text-decoration: none;
						}

						&.login {
							a {
								display: inline-block;
								font-size: 1rem;
								border: 0;
								text-align: center;
								line-height: 1em;
								color: #fff;
								background-color: #0e242f;
								border-radius: 0rem;
								padding: .6875rem 1.3125rem !important;
								font-weight: 400;
							}
						}

						&.register {
							margin-top: 12px;

							a {
								background-color: transparent;
								background: none;
								color: #36a5ec;
								font-size: 1rem;
								line-height: 1.2;

								&:hover {
									text-decoration: underline;
								}
							}
						}
					}
				}

				> a {
					text-overflow: ellipsis;
					text-decoration: none;
					color: #0e242f;

					&:hover {
						text-decoration: underline;
					}
				}

				&.overview-active {
					font-weight: bold;
				}

				&.logout {
					margin: 0.5rem 0;

					img {
						vertical-align: middle;
						width: 1rem;
						height: 1rem;
					}

					font-weight: 500;
				}

				&:last-child {
					border: none;
				}
			}
		}
	}
}

$padding: .6rem .5rem;

@media (max-width: 767px) {

	@keyframes moveFromLeft {
		0% {
			left: -17.5rem;
		}
		100% {
			left: 0;
		}
	}

	div.account-menu {

		animation-name: moveFromLeft;
		animation-duration: 0.2s;
		animation-timing-function: ease-in;

		position: absolute;
		background-color: rgb(245, 245, 248);
		left: 0;
		top: 0;
		height: 100vh;
		min-width: 17.5rem;
		z-index: 9999;

		> div {
			display: flex;
			justify-content: space-between;
			background-color: rgb(14, 36, 47);
			color: #fff;
			padding: $padding;
			font-size: 0.875rem;
			font-weight: bold;
			vertical-align: middle;

			img {
				vertical-align: middle;
			}
		}

		h3 {
			padding: $padding;
			font-weight: bold;
			margin: 0;
			margin-top: 0.5rem;
			border-bottom: 1px solid #dadae5;
		}

		ul {
			li {
				list-style: none;
				margin: 0;
				background: #fff;

				&.buttons {
					text-align: center;
					padding-bottom: .375rem;
					padding: 1rem;

					div {
						border: none;

						a {
							text-decoration: none;
						}

						&.login {
							a {
								display: inline-block;
								font-size: 1rem;
								border: 0;
								text-align: center;
								line-height: 1em;
								color: #fff;
								background-color: #0e242f;
								border-radius: 0rem;
								padding: .6875rem 1.3125rem !important;
								font-weight: 400;
							}
						}

						&.register {
							margin-top: 12px;

							a {
								background-color: transparent;
								background: none;
								color: #36a5ec;
								font-size: 1rem;
								line-height: 1.2;

								&:hover {
									text-decoration: underline;
								}
							}
						}
					}
				}

				padding: $padding;
				border-bottom: 1px solid #dadae5;

				&.logout {

					a {
						font-size: 1rem;

						img {
							position: relative;
							top: 0.1rem;
							width: .875rem;
							height: .875rem;
						}
					}

					font-weight: 500;
				}

				a {
					text-decoration: none;
					color: #0e242f;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

}

</style>
