<template>
  <div class='search-bar'>
    <form @submit='submit'>
      <language-selection v-model:selection="languageCombination" />
      <div class='bar'> 
        <input autofocus ref='search' autocomplete='off' placeholder='Begriff eingeben...' v-model='searchInput'>
        <a class='button-submit' :href='dictionaryRef'>{{isMobile ? 'los' : 'übersetzen'}}</a>
      </div>
    </form>
  </div>
</template>

<script>
  import LanguageSelection from '@/components/LanguageSelection';

  export default {
    name: 'SearchBar',
    components: { LanguageSelection },
    data() {
      return {
        searchInput: '',
        languageCombination: 'deutsch-englisch',
        isMobile: true
      }
    },
    methods: {
      handleLanguageSelection(languageCombi) {
        this.languageCombination = languageCombi;
      },
      submit(e) {
        console.log('submit', e, this.searchInput);
        e.preventDefault();
        if (this.searchInput) {
          document.location=this.dictionaryRef;
        }
        return false;
      }
    },
    computed: {
      dictionaryRef() {
        return `https://de.langenscheidt.com/${this.languageCombination}/${this.searchInput}`;
      }
    }
  }
</script>

<style lang='scss' scoped>
  @import '../assets/variables';

  .search-bar {
    width: 100%;

    form {
      .bar {
        display: flex;

        input {
          height: 2.5rem;
          font-size: 1rem;
          padding: 0 2.0625rem 0 .6875rem;
          line-height: 1.375rem;
          letter-spacing: .0625rem;
          border: 0;
          border-radius: 0;
          width: 75%;

          &:focus {
            outline: 0;
          }
        }

        input::placeholder {
          color: $color-grey-light;
          font-size: .875rem;
        }

        .button-submit {
          display: inline-block;
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25%;
          height: 2.5rem;
          background-color: $color-secondary;
          text-align: center;
          letter-spacing: 1px;
          color: white;
          font-size: 1rem;
          padding: .625rem;
          box-sizing: border-box;

          &:hover {
            box-shadow: 6px 6px 11px rgba(0,0,0,.2);
          }
        }
      }
    }

    @media (min-width: $break-md) {
      form {
        .bar {
          input { 
            height: 3.75rem;
            padding: 0 2.0625rem;

            &::placeholder {
              font-size: 1rem;
            }
          }

          .button-submit {
            padding: 1.25rem 2.5rem;
            height: 3.75rem;
          }
        }
      }
    }

  }
</style>