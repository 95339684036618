<template>
  <div class="shopping-cart">
    <a href="/shop/checkout/cart" ><i class="icon-add-to-basket" aria-hidden="true"></i></a>
    <span v-if="shoppingCartItems>0" class="shopping-cart--items">{{ shoppingCartItems }}</span>
  </div>
</template>

<script>
  export default {
    name: "ShoppingCart",
    data() {
      return {
        shoppingCartItems: 0
      };
    },
    mounted() {
      try {
        fetch('/shop/frontend/gzsso/getBasketState')
          .then(response => {
            if (response.status!==200) {
              throw `Could not fetch shopping cart item count - got ${response.status}`;
            }
            response.json().then(data => {
              if (data.sBasketQuantity && !isNaN(data.sBasketQuantity)) {
                this.shoppingCartItems = data.sBasketQuantity;
              }
            })
          });
      } catch (e) {
        console.error('Could not fetch shopping cart item count', e);
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import '../assets/variables.scss';


  .shopping-cart {
    width: 1.25rem;
    height: 1.25rem;
    padding: .3125rem;
    background: $color-secondary;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    a {
      text-decoration: none;
    }

    i {
      color: white
    }

    .shopping-cart--items {
      position: absolute;
      display: inline-block;
      width: 1.375rem;
      height: 1.375rem;
      right: -0.25rem;
      top: -0.25rem;
      line-height: 1.5rem;
      font-size: .6875rem;
      font-weight: 500;
      border-radius: 50%;
      text-align: center;
      background: #004860;
      color: #fff;
    }

    @media (min-width: $break-md) {
      width: 1.75rem;
      height: 1.75rem;
      padding: 0.5rem;
    }

    &:hover {
      box-shadow: 6px 6px 11px rgba(0,0,0,.2);
    }
  }

</style>