<template>
  <header>
    <header-desktop class="desktop"/>
    <header-mobile class="mobile"/>
  </header>
</template>

<script>
  import HeaderDesktop from "@/components/HeaderDesktop";
  import HeaderMobile from "@/components/HeaderMobile";

  export default {
    name: 'App',
    components: {
      HeaderDesktop,
      HeaderMobile
    }
  }
</script>

<style lang="scss" scoped>
  @import './assets/variables.scss';

  header {
    background: $color-primary;
  }

  .desktop {
    display: none;
  }

  @media (min-width: $break-md) {
    .desktop {
      display: block;
    }
    .mobile {
      display: none;
    }
  }
</style>
