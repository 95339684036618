<template>
  <div class="burger-menu" :class="{ active: isActive }">
    <button class="toggle" @click="$emit('update:isActive', !isActive)">
      <span class="sr-only">Toggle navigation</span>
      <span class="burger-bar"></span>
      <span class="burger-bar"></span>
      <span class="burger-bar"></span>
    </button>
    <nav>
      <div class="main">
        <ul>
          <li>
            <SpaLink class="button-login" href="/account" v-if="user !== null" >Mein Konto</SpaLink>
            <a class="button-login" href="#" v-else @click.prevent="enforceLogin">Anmelden</a>
          </li>
          <li v-for="(item, index) in mainNavItems" :key="index" @click="openSecondaryLevel(item, 'main')">
            {{ item.label }}
          </li>
          <SecondaryLevel :data="secondaryItem" v-model:isOpen="mainSecondaryIsOpen" type="main"/>
        </ul>
      </div>
      <div class="meta">
        <ul>
          <li v-for="(item, index) in metaNavItems" :key="index" @click="openSecondaryLevel(item, 'meta')" :href="item.url">
            {{ item.label }}
          </li>
          <SecondaryLevel :data="secondaryItem" v-model:isOpen="metaSecondaryIsOpen" type="meta"/>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
  import mainNavItems from '../data/header-items.main.json';
  import metaNavItems from '../data/header-items.top.json';
  import SpaLink from './SpaLink.vue';
  import SecondaryLevel from './SecondaryLevel.vue';
  import { requestAuth } from "@pons/auth";

  export default {
    name: "BurgerMenu",
    components: { SpaLink, SecondaryLevel },
    props: ["isActive"],
    data() {
      return {
        mainNavItems,
        metaNavItems,        
        user: 'null',
        mainSecondaryIsOpen: false,
        metaSecondaryIsOpen: false,
        secondaryItem: undefined,
      }
    },
    emits: ['update:isActive'],
    methods: {
      async enforceLogin() {
        this.user = await requestAuth({
          performAuthOnEmpty: true
        });
      },
      openSecondaryLevel(data, type) {
        this.secondaryItem = data;
        if (type === "main") {
          this.mainSecondaryIsOpen = true;
        }
        else if (type === "meta") {
          this.metaSecondaryIsOpen = true;
        }
      }
    },
    async created() {
      this.user = await requestAuth({performAuthOnEmpty: false});
    },
  }
</script>

<style lang="scss" scoped>
  @import '../assets/variables.scss';

  nav {
    max-height: 0;
    transition: max-height .2s ease-out;
    transition-delay: .1s;
    width: 100%;
    position: fixed;
    top: 3.75rem;
    left: 0;
    z-index: 1000;
    background: $color-primary;
    overflow-y: scroll;
    overflow-x: hidden;
    background: white;

    .main, .meta {
      background: $color-primary;

      ul {
        list-style: none;
        border-top: 2px solid white;
        padding: 2.1875rem 0;
        position: relative;
        margin: 0 0.9375rem;
        opacity: 0;
        transition: opacity .2s;


        li {
          text-transform: uppercase;
          color: $color-accent-darkest;
          line-height: 2.5em;
          text-align: center;
 
          .button-login {
            display: inline-block;
            padding: 1.25rem 2.5rem;
            color: white;
            background-color: $color-accent-darkest;
            font-size: 1rem;
            border: 0;
            text-align: center;
            line-height: 1em;
            color: #fff;
            background-color: $color-secondary;
            border-radius: 6.25rem;
            padding: .6875rem 1.3125rem!important;
            font-weight: 300;
            margin-bottom: 1rem;
          }

          a {
            color: $color-accent-darkest;
            text-decoration: none;
          }
        } 
      }
    }

    .meta {
      background: white;
      padding: 1.5625rem 0 1.875rem;

      ul {
        li {
          text-align: center;
          color: $color-grey-light;
          font-size: .8125rem;
          font-weight: 300;
          line-height: 2.46em;
          letter-spacing: .03em;
        }
      }
    }
  }

  .active {
    nav {
      transition: max-height .2s ease-in;
      max-height: calc(100vh - 60px);

      .main, .meta {

        ul {
          opacity: 1;
          transition: opacity .6s;
          transition-delay: .1s;
        }
      }
    }

    .burger-bar {
      transition: transform .2s;
    }

    .burger-bar:nth-child(2) {
      transform: rotate(45deg) translateY(2px) translateX(5px);
    }

    .burger-bar:nth-child(3) {
      display: none;
    }

    .burger-bar:nth-child(4) {
      transform: rotate(-45deg) translateY(-4px) translateX(7px);
    }
  }

  .toggle {
    background: transparent;
    border: none;
    height: 0.875rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:focus, &:active {
      outline: 0;
    }

    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      overflow: hidden;
      clip: rect(0,0,0,0);
      border: 0;
    }

    .burger-bar {
      background: $color-accent-darkest;
      display: block;
      width: 22px;
      height: 2px;
      border-radius: 1px;
    }
  }
  
</style>