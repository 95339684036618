<template>
  <div class="language-selection" >
    <a @click="toggleDropdown()"> {{ activeLanguageCombination.split(" - ")[0] }}</a>
    <a @click="switchLanguages()"><i class="fa fa-exchange"/></a>
    <a @click="toggleDropdown()">{{ activeLanguageCombination.split(" - ")[1] }}</a>
    <a @click="toggleDropdown()"><i class="fa fa-caret-down"></i></a>

    <ul class="dropdown-menu"  :class="{ active: isActive }">

      <li v-for="language in languageList" :key="language" @click="setLanguageCombination(language)">
        <a v-if="language !== 'Deutsch' && language !== 'Fremdwörterbuch'">
          Deutsch - {{ language }}
        </a>
        <a v-if="language === 'Fremdwörterbuch'">
          {{ language }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "LanguageSelection",
    props: {
      selection: String
    },
    emits: ['update:selection'],
    data() {
      return {
        isActive: false,
        languageList: [ "Englisch", "Arabisch", "Bulgarisch", "Chinesisch", "Dänisch", "Französisch", "Griechisch", "Isländisch", "Italienisch", "Japanisch", "Kroatisch", "Latein", "Niederländisch", "Norwegisch", "Persisch", "Polnisch", "Portugiesisch", "Rumänsisch", "Russisch", "Schwedisch", "Serbisch", "Slowakisch", "Spanisch", "Tschechien", "Türkisch", "Ungarisch", "Fremdwörterbuch"],
        activeLanguageCombination: "Deutsch - Englisch"
      }
    },
    methods: {
      toggleDropdown() {
        this.isActive = !this.isActive;
      },
      setLanguageCombination(language) {
        this.toggleDropdown();
        this.activeLanguageCombination = "Deutsch - " + language;
        this.$emit('update:selection',  "deutsch-" + language.toLowerCase())
      },
      switchLanguages() {
        this.$emit('update:selection', this.activeLanguageCombination.split("-").reverse().join("-").toLowerCase())
        this.activeLanguageCombination = this.activeLanguageCombination.split(" - ").reverse().join(" - ");

      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../assets/variables.scss";

  .language-selection {
    margin-bottom: 1rem;
    position: relative;
    color: $color-accent-darkest;
    font-size: 1rem;
    cursor: pointer;
    white-space: nowrap;

    .icon-arrow-accordion-down {
      font-size: .6rem;
      margin-left: .375rem;
    }
  
    a {
      background: 0 0;
      border: 0;
      text-transform: capitalize;
      font: inherit;
      padding: 0 .375rem;
    }

    .dropdown-menu {
      max-height: 0;
      position: absolute;
      z-index: 5000;
      top: 0;
      left: 0;
      background: white;
      overflow-y: auto;
      list-style: none;
      box-shadow: 0 1px 11px rgba(0,0,0,.15);

      &.active {
        max-height: 30vh;
        padding: .25rem 0;
      }

      li {
        padding: .875rem 2.8125rem .625rem;
        font-size: 1rem;

        &:hover {
          color: $color-secondary;
        }
      }
    }
  }


</style>