<template>
  <div class="logo">
    <img width="148" height="148" class="desktop" src="../assets/langenscheidt-logo-wortmarke-desktop.svg" alt="Langenscheidt Logo">
    <img width="169" height="62" class="tablet" src="../assets/langenscheidt-logo-wortmarke-tablet.svg" alt="Langenscheidt Logo">
    <img width="56" height="56" class="mobile" src="../assets/langenscheidt-logo-bildmarke-mobile.svg" alt="Langenscheidt Logo">
  </div>
</template>

<script>
  export default {
    name: "Logo"
  }
</script>

<style lang="scss" scoped>
  @import '../assets/variables.scss';

  .logo {
    display: flex;
  }

  .desktop {
    display: none;
    height: 148px;
    width: 148px;
  }

  .tablet {
    display: none;
  }

  .mobile {
    position: relative;
    left: 4px;
  }


  @media (min-width: $break-xs) {
    .mobile {
      display: none;
    }

    .tablet {
      display: block;
    }
  }

  @media (min-width: $break-md) {
    .tablet {
      display: none;
    }

    .desktop {
      display: block;
    }
  }
</style>