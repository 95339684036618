<template>
  <div class="sub-navigation-desktop">
    <div class="container">
      <div v-if="data.children.length === 0" class="tab" >
        <div class="search-container">
          <SearchBarDict/>
        </div>
        <p class="dict-info">Was kann unser <a class="hightlighted" href="https://de.langenscheidt.com/">Online-Wörterbuch</a>?</p>
      </div>
      <div v-else class="tab cols">
        <div class="col" v-for="item in data.children" :key="item.label">
          <div class="col-title">
            <SpaLink v-if="!item.url.includes('https')" :href="item.url" v-html="item.label"></SpaLink>
            <a v-else :href="item.url" v-html="item.label"></a>
          </div>
          <ul>
            <li v-for="child in item.children" :key="child.label">
              <SpaLink v-if="!child.url.includes('https')" :href="child.url" v-html="child.label"></SpaLink>
              <a v-else :href="child.url" v-html="child.label"></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SearchBarDict from "./SearchBarDict";
  import SpaLink from './SpaLink.vue';

  export default {
    name: "SubNavigation",
    props: ["data"],
    components: { SearchBarDict, SpaLink }
  }
</script>

<style lang="scss" scoped>
  @import '../assets/variables.scss';

  .container {
    padding: 0 1.25rem;
    border-top: 2px solid white;
    visibility: hidden;


    @media (min-width: $break-xl) {
      width: 75rem;
      margin: 0 auto;
    }
  }

  .sub-navigation-desktop {
    position: absolute;
    background: $color-primary;
    width: 100%;
    max-height: 0;
    transition: max-height .3s ease-out; 
    font: inherit;
    color: $color-accent-darkest;
    font-size: 1rem;
    line-height: 1.11em;
    z-index: 2000;

    .search-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2.8125rem 0;

      .search-bar {
        width: 66.66667%;
      }
    }

    .dict-info {
      text-align: center;
      padding-bottom: 3.125rem;
      .hightlighted {
        margin: 0 .625rem;
        font-family: Handelson;
        font-size: 2.375rem;
        line-height: 1;
        color: white;
        border-bottom: 2px solid $color-accent-darkest;

        &:hover {
          text-decoration: none;
          color: $color-secondary;
          cursor: pointer;
        }
      }
    }

    a {
      color: $color-accent-darkest;
      text-decoration: none;

      &:hover {
        color: $color-accent-darkest;
        text-decoration: underline;
      }
    }

    .tab {
      opacity: 0;

      &.cols {
        display: flex;
        justify-content: space-between;

        .col {
          padding: 3.125rem 0;
        }

        .col-title {
          line-height: 1.11em;
          letter-spacing: .34px;
          font-weight: 700;
          margin-bottom: 2.4375rem;
        }

        ul {
          list-style: none;
          line-height: 1.44em;
          font-weight: 300;
          letter-spacing: 0;
        }
      }
    }



    &.open {
      max-height: 800px;
      transition: max-height .3s ease-in; 
      box-shadow: 0 5px 11px -4px rgb(0 0 0 / 15%);

      .container {
        visibility: visible;
      }

      .tab {
        opacity: 1;
        transition: opacity .6s;
        transition-delay: .15s;
      }
    }

    @media (min-width: $break-xl) {
      font-size: 1.125rem;
      line-height: 1.44em;
    }
  }
</style>